<template>
    <section
        v-if="getterTypes.length"
        class="wrapper wrapper--xs"
    >
        <div class="feedback_modal-content">
            <div class="feedback_modal__header">
                <div class="feedback_modal__header-data">
                    <p class="feedback_modal__header-title">
                        <span class="feedback_modal__header-title-type">
                            {{ getTypeLongName ? getTypeLongName : '' }}
                        </span>
                        <span
                            class="feedback-order-title report"
                        >
                            <span class="tool">
                                <div>«{{ order.title }}»</div>
                            </span>
                            <span class="feedback-order-title__text">
                                <i>«</i><span>{{ order.title }}</span><i>»</i>
                            </span>
                        </span>
                    </p>
                    <div class="feedback_modal__header-text">
                        <div
                            class="card_body__detail"
                        >
                            <div class="card_body__detail-title">
                                Order ID
                            </div>
                            <div class="card_body__detail-counter">
                                <Clipboard />
                                {{ order.orderid }}
                            </div>
                        </div>

                        <div
                            class="card_body__detail"
                        >
                            <div class="card_body__detail-title">
                                Order date
                            </div>
                            <div class="card_body__detail-counter">
                                <Clock />
                                {{ order.created_at | moment_short_text }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="feedback_modal__progress">
                    <span
                        :style="`width: ${progress}%`"
                    />
                </div>
            </div>

            <div>
                <div class="feedback_section">
                    <div
                        v-if="order.writer"
                        class="feedback_section_writer"
                    >
                        <avatar
                            :avatar-url="order.writer.avatar_url"
                            :nickname="order.writer.nickname"
                            :firstname="order.writer.firstname"
                            :lastname="order.writer.lastname"
                            :user="'writer'"
                        />
                        <div class="feedback_modal__header-info">
                            <span
                                v-if="order.writer.id"
                                class="feedback_modal__header-info--id"
                            >
                                {{ 'ID: ' + order.writer.id }}
                            </span>
                            <span class="feedback_modal__header-name">{{ getWriterTitle }}</span>
                        </div>
                    </div>
                    <div class="feedback_section_info">
                        <div
                            v-if="order.pages"
                            class="feedback_section_info-col"
                        >
                            <div class="feedback_section_info-title">
                                {{ order.pages === 1 ? 'Page' : 'Pages' }}
                            </div>
                            <div class="feedback_section_info-counter">
                                <FileOutlineIcon />
                                {{ order.pages }}
                            </div>
                        </div>
                        <div
                            v-if="order.slides"
                            class="feedback_section_info-col"
                        >
                            <div class="feedback_section_info-title">
                                {{ order.slides === 1 ? 'Slide' : 'Slides' }}
                            </div>
                            <div class="feedback_section_info-counter">
                                <ImageOutlineIcon />
                                {{ order.slides }}
                            </div>
                        </div>
                        <div
                            v-if="order.charts"
                            class="feedback_section_info-col"
                        >
                            <div class="feedback_section_info-title">
                                {{ order.charts === 1 ? 'Chart' : 'Charts' }}
                            </div>
                            <div class="feedback_section_info-counter">
                                <ChartBoxOutlineIcon />
                                {{ order.charts }}
                            </div>
                        </div>
                        <div
                            v-if="order.problems"
                            class="feedback_section_info-col"
                        >
                            <div class="feedback_section_info-title">
                                {{ order.problems === 1 ? 'Problem' : 'Problems' }}
                            </div>
                            <div class="feedback_section_info-counter">
                                <AlertOutlineIcon />
                                {{ order.problems }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="feedback_section feedback_section--instruction">
                    <div
                        class="feedback_section_info-col"
                    >
                        <div class="feedback_section_info-title">
                            Instrustions
                        </div>
                        <div class="">
                            {{ order.details }}
                            <!-- <span
                                v-if="order.details.length > 100"
                                class="feedback_section--instruction_toggle"
                                @click="showInstruction = !showInstruction"
                            >
                                {{ showInstruction ? 'Hide' : 'Show more' }}
                            </span> -->
                        </div>
                    </div>
                </div>
            </div>

            <template>
                <div
                    v-for="(item, index) in tabs"
                    v-show="activeTab === index"
                    :key="index"
                    class="feedback_modal__body"
                >
                    <div>
                        <p class="feedback_modal__body-title">
                            <b>
                                {{ activeTab+1 }} of {{ tabs.length }}
                            </b>
                            {{ tabs[activeTab].title }}
                        </p>
                        <div
                            v-if="tabs[activeTab].name !== 'message'"
                            class="feedback_modal__body-rating"
                        >
                            <p class="feedback_modal__body-item__title">
                                {{ tabs[activeTab].name }}
                            </p>
                            <star-rating
                                v-model="form[tabs[activeTab].name]"
                                :star-size="30"
                                :padding="5"
                                :show-rating="false"
                                :active-color="themecolor"
                            />
                        </div>
                        <div
                            v-show="(form[tabs[activeTab].name] && form[tabs[activeTab].name] < 4) || tabs[activeTab].name === 'message'"
                            class="feedback_modal__body-rating_text"
                        >
                            <form-textarea
                                v-model="$v.form[tabs[activeTab].details_name].$model"
                                :legend="tabs[activeTab].question"
                                type="text"
                                :name="tabs[activeTab].question"
                                :field.sync="form[tabs[activeTab].details_name]"
                                :errors="validationMessage($v.form[tabs[activeTab].details_name])"
                                @input.native="$v.form[tabs[activeTab].details_name].$touch();"
                                @blur.native="$v.form[tabs[activeTab].details_name].$touch();"
                            />
                        </div>
                    </div>
                </div>
            </template>

            <!-- footer buttons -->
            <div style="display: flex; justify-content: space-between">
                <footer class="feedback_modal__btns">
                    <button
                        :disabled="activeTab === 0"
                        depressed
                        block
                        class="btn-base btn-border"
                        @click.prevent="previousStep()"
                    >
                        Back
                    </button>

                    <button
                        v-if="activeTab < 5"
                        text
                        block
                        class="btn-base btn-main feedback_modal__send"
                        @click.prevent="nextStep(tabs[activeTab].name)"
                    >
                        Next
                    </button>
                    <!-- final button -->
                    <RecaptchaButton
                        v-if="isCurrentStepValid && activeTab >=5"

                        :validate-params="$v.$invalid"
                        @checkField="submit"
                        @captchaVerify="validateForm"
                    >
                        <template slot="button-content">
                            <!-- <button-loader v-if="submitLoading" /> -->
                            Send
                        </template>
                    </RecaptchaButton>
                    <!-- final button -->
                </footer>
            </div>
            <!-- footer buttons -->
        </div>
    </section>
</template>

<script>
// Components
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import StarRating from 'vue-star-rating'
import ImageOutlineIcon from 'mdi-vue/ImageOutline.vue'
import ChartBoxOutlineIcon from 'mdi-vue/ChartBoxOutline.vue'
import AlertOutlineIcon from 'mdi-vue/AlertOutline.vue'
import FileOutlineIcon from 'mdi-vue/FileOutline.vue'
import { eventBus } from '@/helpers/event-bus'
import Api from '@/helpers/api/index.js'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/feedback/Rules'
import { formMessages } from '@/validation/feedback/Message'
import filtersMixin from '@/mixins/filtersMixin.js'
import Avatar from '@/components/common/Avatar.vue'
import Clipboard from '@/components/icons/Сlipboard.vue'
import Clock from '@/components/icons/Сlock.vue'
import RecaptchaButton from '@/components/common/RecaptchaButton';
import {
    GET_PRICING
} from '@/store/modules/config/action-types'

const {
    mapActions: mapConfigActions
} = createNamespacedHelpers('config')
const Env = require('@/helpers/environment/index.js');

const EnvSettings = new Env(process.env.VUE_APP_DOMAIN);
export default {
    components: {
        Avatar,
        StarRating,
        Clipboard,
        Clock,
        ImageOutlineIcon,
        ChartBoxOutlineIcon,
        AlertOutlineIcon,
        FileOutlineIcon,
        RecaptchaButton
    },
    validations: {
        ...validationRules
    },
    mixins: [
        validationMixin,
        formValidationMixin,
        filtersMixin
    ],
    data: () => ({
        order: {},
        showInstruction: false,
        form: {
            delivery: null,
            delivery_details: '',
            content: null,
            content_details: '',
            communication: null,
            communication_details: '',
            website: null,
            website_details: '',
            support: null,
            support_details: '',
            message: ''
        },
        activeTab: 0,
        themecolor: EnvSettings.styleSetting.color
    }),
    computed: {
        ...mapGetters('config', [
            'getterTypes'
        ]),
        progress() {
            const calculatedProgress = ((this.activeTab) / this.tabs.length) * 100
            return calculatedProgress
        },
        isCurrentStepValid() {
            switch (this.activeTab) {
            case 0:
                return !this.$v.form.delivery.$invalid && !this.$v.form.delivery_details.$invalid
            case 1:
                return !this.$v.form.content.$invalid && !this.$v.form.content_details.$invalid
            case 2:
                return !this.$v.form.communication.$invalid && !this.$v.form.communication_details.$invalid
            case 3:
                return !this.$v.form.website.$invalid && !this.$v.form.website_details.$invalid
            case 4:
                return !this.$v.form.support.$invalid && !this.$v.form.support_details.$invalid
            case 5:
                return !this.$v.form.message.$invalid
            default:
                return false
            }
        },
        tabs() {
            return [
                {
                    active: false,
                    name: 'delivery',
                    details_name: 'delivery_details',
                    title: 'Was the project delivered on time?',
                    question: 'Describe your problem',
                    details_min: 20,
                    details_max: 200
                },
                {
                    active: false,
                    name: 'content',
                    details_name: 'content_details',
                    title: 'Was the content of good quality?',
                    question: 'Describe your problem'
                },
                {
                    active: false,
                    name: 'communication',
                    details_name: 'communication_details',
                    title: 'Did the writer communicate well?',
                    question: 'Describe your problem'
                },
                {
                    active: false,
                    name: 'website',
                    details_name: 'website_details',
                    title: 'How would you rate our website?',
                    question: 'Describe your problem'
                },
                {
                    active: false,
                    name: 'support',
                    details_name: 'support_details',
                    title: 'Was our support team helpful?',
                    question: 'Describe your problem'
                },
                {
                    active: false,
                    name: 'message',
                    details_name: 'message',
                    title: 'Please tell us about your overall experience',
                    question: 'Message'
                }
            ]
        },
        getWriterTitle() {
            if (this.order?.writer?.nickname) return this.order.writer.nickname
            if (this.order?.writer?.firstname) return this.order.writer.firstname
            return 'Writer'
        },
        orderid() {
            return this.$route.query.orderid || this.$route.query.id
        },
        hash() {
            return this.$route.query.hash
        },
        getTypeLongName() {
            if (this.getterTypes.length && this.order.type) {
                return this.getterTypes.find((item) => item.value === this.order.type).text
            }
            return null
        }
        // getInstruction() {
        //     return this.showInstruction ? this.order.details : this.order.details.slice(0, 100)
        // }
    },
    watch: {
        delivery(val) {
            if (+val >= 4) {
                this.delivery_details = ''
            }
        },
        content(val) {
            if (+val >= 4) {
                this.content_details = ''
            }
        },
        communication(val) {
            if (+val >= 4) {
                this.communication_details = ''
            }
        },
        website(val) {
            if (+val >= 4) {
                this.website_details = ''
            }
        },
        support(val) {
            if (+val >= 4) {
                this.support_details = ''
            }
        }
    },
    async created() {
        if (!this.hash || !this.orderid) {
            this.$router.push({ path: '/' })
            return
        }
        await this.getOrderData()
        await this.getTypes()
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapConfigActions([
            GET_PRICING
        ]),
        nextStep(name) {
            this.$v.$reset()
            if ((name === 'delivery') && !this.$v.form.delivery.$invalid && !this.$v.form.delivery_details.$invalid) {
                this.activeTab += 1
            } else if (name === 'content' && !this.$v.form.content.$invalid && !this.$v.form.content_details.$invalid) {
                this.activeTab += 1
            } else if (name === 'communication' && !this.$v.form.communication.$invalid && !this.$v.form.communication_details.$invalid) {
                this.activeTab += 1
            } else if (name === 'website' && !this.$v.form.website.$invalid && !this.$v.form.website_details.$invalid) {
                this.activeTab += 1
            } else if (name === 'support' && !this.$v.form.support.$invalid && !this.$v.form.support_details.$invalid) {
                this.activeTab += 1
            } else {
                this.$v.$touch()
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error');
            }
        },
        previousStep() {
            if (this.activeTab > 0) {
                this.activeTab -= 1
            }
        },
        async submit(payload) {
            this.loading = true
            const data = {
                ...this.form,
                orderid: this.order.orderid,
                hash: this.hash,
                recaptcha: payload
            }
            Object.entries(data).forEach(([key, value]) => {
                if (value === '') {
                    delete data[key]
                }
            });
            try {
                await Api.post('/api/testimonial/guest/create', data)
                // this.$router.push({ name: 'successful_rate' })
                eventBus.$emit('showSnackBar', 'Your feedback has been submited. Thank you!', 'success')
                this.$router.push({ name: 'success_rate_writer' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.loading = false
            }
        },
        async validateForm(payload) {
            await this.$v.$touch()
            if (!this.$v.$invalid) {
                this.submit(payload)
            }
        },
        async getOrderData() {
            try {
                const { data } = await Api.get('/api/testimonial/guest/get-order-details', {
                    orderid: this.orderid,
                    hash: this.hash
                })
                this.order = data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
                this.$router.push({ path: '/' })
            }
        },
        async getTypes() {
            await this[GET_PRICING]()
        }
    }
}
</script>
<style lang="scss" scoped>
.feedback_modal{
    position: fixed;
    overflow: hidden;
    background-color: rgba(11, 19, 31, .45);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    overscroll-behavior: contain;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    &-content{
        width: 100%;
        background-color: #fff;
        box-shadow: 0 10px 30px 0 rgb(0 0 0 / 8%);
        padding: 20px;
        position: relative;
    }
    &__header{
        position: relative;
        padding-bottom: 20px;
        &:before{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: #EAEFF2;
        }
        &-data{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .avatar-wrapper{
                margin-right: 20px;
            }
        }
        &-text{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 16px;
            line-height: 22px;
            color: $font-color-main;
            &-order{
                >img{
                    display: none;
                }
                &::before{
                    content: "Order ID:";
                    margin-right: 10px;
                    display: block;
                }
            }
            p {
                display: flex;
                align-items: center;
                margin: 5px 0;
                & > span{
                    font-weight: 700;
                    color: $font-color-main;
                    margin-right: 10px;
                }
                img{
                    margin-right: 10px;
                }
            }
        }
        &-name {
            text-transform: capitalize;
            font-weight: bold;
        }
    }
    &__body{
        padding-top: 20px;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: #EAEFF2;
        }
        &-title{
            font-size: 16px;
            color: $font-color-main;
            font-weight: 700;
            margin-bottom: 20px;
        }
        &-item{
            &__title{
                margin-right: 60px;
                text-transform: capitalize;
            }
        }
        &-rating{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }
        &-rating_text{
            margin-top: 30px;
            margin-bottom: 20px;
        }
    }
    &__btns{
        display: flex;
        align-items: center;
        .btn-base{
            min-width: 110px;
            &:disabled{
                background: rgba(0,0,0,.12)!important;
                border: none;
                color: rgba(0,0,0,.26)!important;
                box-shadow: none !important;
                cursor: default;
                pointer-events: none;
            }
            &:first-child{
                margin-right: 20px;
            }
        }
    }
    &__progress{
        position: absolute;
        width: 100%;
        top: -20px;
        left: -20px;
        right: -20px;
        background: #EAEFF2;
        span{
            height: 4px;
            background: $border-gradient;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
    }
}
.feedback_modal__header-title{
    padding: 15px 0;
    &-type {
        font-weight: bold;
        padding-right: 10px;
    }
}
.card_body__detail{
    @media all and (max-width: 992px){
        margin-bottom: 0px;
        padding: 15px 0;
    }
}
.feedback_modal__header-avatar{
    display: flex;
    padding: 15px 0;
}
.feedback_modal__header-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
}
.feedback_modal__header-info--id {
    margin-bottom: 5px;
    color: #AFB2B9;
}
.feedback-order-title{
    &__text {
        overflow: hidden;
        span {
            cursor: pointer;
            max-width: 150px;
            align-items: center;
            text-overflow: ellipsis;
            display: inline-block;
            overflow-x: hidden;
            overflow-y: hidden;
            position: relative;
            top: 4px;
            white-space: nowrap;
            line-height: 1.6;
        }
    }
}
.feedback_section{
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    &_writer{
        display: flex;
        .avatar-wrapper{
            margin-right: 20px;
        }
    }
    &_info{
        width: 50%;
        display: flex;
        &-col {
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
        }
        &-title {
            display: block;
            color: #afb2b9;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            margin-bottom: 8px;
            user-select: none;
        }
        &-counter{
            display: flex;
            align-items: center;
            .mdi {
                margin-right: 10px;
                svg {
                    fill: $main-color !important;
                }
            }
        }
    }
}
.feedback_section--instruction{
    width: 50%;
    &_toggle{
        color: blue;
        cursor: pointer;
        text-decoration: underline;
    }
}
</style>
